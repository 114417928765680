import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Header from "../InitialPage/Sidebar/Header";
import Sidebar from "../InitialPage/Sidebar/Sidebar";
import { pagesRoute, publicRoutes } from "./router.link";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import ThemeSettings from "../InitialPage/themeSettings";
// import CollapsedSidebar from "../InitialPage/Sidebar/collapsedSidebar";
import Loader from "../feature-module/loader/loader";
// import Signin from "../feature-module/pages/login/signin";
// import HorizontalSidebar from "../InitialPage/Sidebar/horizontalSidebar";
//import LoadingSpinner from "../InitialPage/Sidebar/LoadingSpinner";

const AllRoutes = () => {
  const data = useSelector((state) => state.toggle_header);
  const me = useSelector((state) => state.me);
  // const layoutStyles = useSelector((state) => state.layoutstyledata);
  const HeaderLayout = () => (
    <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
      <Header />
      {/* {layoutStyles == "collapsed" ? (
        <CollapsedSidebar />
      ) : layoutStyles == "horizontal" ? (
        <HorizontalSidebar />
      ) : (
        <Sidebar />
      )} */}
      <Sidebar />
      <Outlet />
      <ThemeSettings />
      <Loader />
    </div>
  );
  
  const Authpages = () => (
    <div className={data ? "header-collapse" : ""}>
      <Outlet />
      <Loader />
      <ThemeSettings />
    </div>
  );

  // const Pospages = () => (
    //   <div>
    //     <Header />
    //     <Outlet />
  //     <Loader />
  //     <ThemeSettings />
  //   </div>
  // );
  const navigate = useNavigate();
  
  useEffect(() => {
    if(!me){
      console.log("me", me);
      navigate('/signin')
    }
  }, [me]);

  return (
    <div>
      <Routes>
        {
          me
            ?
            // <Route path="/pos" element={<Pospages />}>
            //   {posRoutes.map((route, id) => (
            //     <Route path={route.path} element={route.element} key={id} />
            //   ))}
            // </Route>
            <Route path={"/"} element={<HeaderLayout />}>
              {pagesRoute.map((route, id) => (
                <Route path={route.path} element={route.element} key={id} />
              ))}
            </Route>
            :
           <>
            <Route path={"/"} element={<Authpages />}>
              {publicRoutes.map((route, id) => (
                <Route path={route.path} element={route.element} key={id} />
              ))}
            </Route>
            </>
        }

        {/* <Route path={"/expenses/"} element={<HeaderLayout />}>
          {expensesRoutes.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route> */}
      </Routes>
    </div>
  );
};
export default AllRoutes;
