import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { Helmet } from "react-helmet";
import { apiUrl } from "../../../lib/constants";
import { useDispatch } from "react-redux";
import { set_me } from "../../../core/redux/action";

const SigninTwo = () => {
  const route = all_routes;
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState()

  const dispatch = useDispatch()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(undefined)
    if (username && password) {
      const myHeaders = new Headers();
      myHeaders.append("authorization", "Bear eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI5ODQ5MzU0Ny0zMGZhLTRiY2UtYTFiYi00NTMyN2MyNTkwMzUiLCJyb2xlIjoiVVNFUiIsImlhdCI6MTcxMjMwMDU1MiwiZXhwIjoxNzEyMzg2OTUyfQ.fO7PE6MLtwkCNSMeDVaSi9UrkR4KBXPpxyOG5SGP70c89_N2BKAuGC66QAMRP2G7wuCHR3tIb7xZKg_vNIdy1QuD8s3Erv7tH9O8YCg9ydBUXrEuPJqblaX-y8u64muiXNKGKN4B8kc7wQm8gwORDvVwUQwy6HfoZf8t7cL_ZRALClE_deo-TWnwks-y_7rmOOV2lQS4KhVSpT_naQwdek8w8y5r1RweHrVOattLPrAq7F2GZQlq4MYOsfNl5IIGmCfptGFzzA_zn5u99TD8CrziBkvOQbz1tfwPCEAtk0IpR4KwusSaBFV0FuDEIDKtkrhRjb89j1PrLRP_Pfbwyg");
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        "username": username,
        "password": password
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      try {
        const response = await fetch(apiUrl + "/user/login", requestOptions)
        if (response.status && response.status < 300) {
          const result = await response.json()
          if (result) {
            dispatch(set_me(result))
            // console.log(result)
          }
        }
        else if (response.status == 401) {
          setError({ message: "Foydalanuvchi nomi yoki(va) maxfiy so'z noto'g'ri kiritildi" })
        }
        else {
          throw "bad request"
        }
      } catch (error) {
        if (error) {
          setError({ message: "Qandaydir ichki xatolik sodir bo'ldi! Qo'llab quvvatlash xodimlariga bog'laning!" })
        }
        console.log("Error in Signing in: ", error);
      }
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kirish | AndaPOS</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className="main-wrapper">
        <div className="account-content">
          <div className="login-wrapper">
            <div className="login-content">
              <form onSubmit={handleSubmit}>
                <div className="login-userset">
                  <div className="login-logo logo-normal">
                    {/* <ImageWithBasePath src="assets/img/logo.png" alt="img" /> */}
                    AndaPOS
                  </div>
                  <Link to={route.dashboard} className="login-logo logo-white">
                    {/* <ImageWithBasePath src="assets/img/logo-white.png" alt /> */}
                    AndaPOS
                  </Link>
                  <div className="login-userheading">
                    <h3>Kirish</h3>
                    <h4>
                      Foydalanish uchun kerakli ma&apos;lumotlarni kiriting.
                    </h4>
                  </div>
                  <div className="form-login">
                    <label>Foydalanuvchi nomi*</label>
                    <div className="form-addons">
                      <input type="text" required value={username} onChange={(e) => setUsername(e.target.value)} className="form-control" />
                      <ImageWithBasePath
                        src="assets/img/icons/mail.svg"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="form-login">
                    <label>Maxfiy so&apos;z*</label>
                    <div className="pass-group">
                      <input required type={showPassword ? "text" : "password"} value={password} onChange={e => setPassword(e.target.value)} className="pass-input" />
                      <span className="fas toggle-password fa-eye-slash" onPointerUp={() => setShowPassword(false)} onPointerDown={() => setShowPassword(true)} onClick={() => { }} />
                    </div>
                  </div>
                  {/* <div className="form-login authentication-check">
                  <div className="row">
                    <div className="col-6">
                      <div className="custom-control custom-checkbox">
                        <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                          <input type="checkbox" />
                          <span className="checkmarks" />
                          Remember me
                        </label>
                      </div>
                    </div>
                    <div className="col-6 text-end">
                      <Link
                        className="forgot-link"
                        to={route.forgotPasswordTwo}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                </div> */}
                  {error && <div className="alert alert-primary" role="alert">
                    {error.message}
                  </div>}

                  <div className="form-login">
                    <button type="submit" className="btn btn-login">
                      Kirish
                    </button>
                  </div>
                  {/* <div className="signinform">
                  <h4>
                    New on our platform?
                    <Link to={route.registerTwo} className="hover-a">
                      {" "}
                      Create an account
                    </Link>
                  </h4>
                </div> */}
                  {/* <div className="form-setlogin or-text">
                  <h4>OR</h4>
                </div>
                <div className="form-sociallink">
                  <ul className="d-flex">
                    <li>
                      <Link to="#" className="facebook-logo">
                        <ImageWithBasePath
                          src="assets/img/icons/facebook-logo.svg"
                          alt="Facebook"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <ImageWithBasePath
                          src="assets/img/icons/google.png"
                          alt="Google"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="apple-logo">
                        <ImageWithBasePath
                          src="assets/img/icons/apple-logo.svg"
                          alt="Apple"
                        />
                      </Link>
                    </li>
                  </ul>
                  <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                    <p>Copyright © 2023 DreamsPOS. All rights reserved</p>
                  </div>
                </div> */}
                </div>
              </form>
            </div>
            <div className="login-img">
              <ImageWithBasePath
                src="assets/img/authentication/login02.png"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SigninTwo;
